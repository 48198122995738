'use client'

import { cn } from '@/lib/utils/utils'
import { I18nDictionary, useI18n } from '../../i18n/lang-context'
import { Button } from '../../ui/button'
import { useDDCalendarDialog } from './dd-calendar-dialog'

const I18N: I18nDictionary = {
  action: {
    en: 'Book a call',
    fr: 'Réserver un appel'
  },
  becomePartner: {
    en: 'Become a partner →',
    fr: 'Devenez partenaire →'
  }
}

export function DDAction({ className }: { className?: string }) {

  const i18n = useI18n(I18N)
  const { openDDCalendarDialog } = useDDCalendarDialog()

  return (
    <Button className={cn('p-8 text-lg', className)} 
      onClick={() => openDDCalendarDialog()} 
      id='Call Booking'>
      {i18n.action}
    </Button>
  )
}

export function DDBecomePartner({ className }: { className?: string }) {

  const i18n = useI18n(I18N)
  const { openDDCalendarDialog } = useDDCalendarDialog()

  return (
    <div className={cn('cursor-pointer font-medium text-primary hover:underline dark:text-primaryBright', className)} onClick={() => openDDCalendarDialog()}>
      {i18n.becomePartner}
    </div>
  )
}
