'use client'

import { useDialogActions } from '@/components/dialog'
import { getLangCode } from '@/components/i18n/lang-context'
import { useConsent } from '@/contexts/ConsentContext'
import { useCookiesConsentDialog } from '../cookies-dialog'

// Define CSS for scrollbar management and conditional display for desktop browsers
const scrollbarCSS = `
@media (min-width: 1024px) {
  .dialog-content-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome and Safari */
  }
  .dialog-content-container {
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer and Edge */
  }
}

.dialog-content-container::-webkit-scrollbar {
  width: 8px;
}

.dialog-content-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 4px;
}

.dialog-content-container {
  overflow-y: auto; /* Show scrollbar only when needed */
}
`

export function useDDCalendarDialog() {
  const { openDialog } = useDialogActions()
  const { consent } = useConsent()

  // Function to open the calendar dialog
  const openCalendarDialog = () => {
    openDialog({ content: <DDCalendarDialogContent />, theme: 'light' })
  }

  // Use Cookies Consent Dialog and pass the callback directly
  const { openCookiesConsentDialog } = useCookiesConsentDialog(openCalendarDialog)

  // Handling calendar dialog based on consent
  const openDDCalendarDialog = () => {
    if (consent.contact) {
      openCalendarDialog()
    } else {
      openCookiesConsentDialog('simple')
    }
  }

  return { openDDCalendarDialog }
}

function DDCalendarDialogContent() {

  const koalendarUrl = getLangCode() === 'en' ?
    'https://koalendar.com/e/digital-deposit-call?embed=true' :
    'https://koalendar.com/e/digital-deposit-call-fr?embed=true'

  return (
    <>
      <style>{scrollbarCSS}</style>
      <div className='dialog-content-container mb-[-60px] w-full bg-white sm:mx-[-16px] sm:w-[90vw] sm:max-w-[896px] md:mb-[-110px] md:mt-[-50px]'>
        <iframe src={koalendarUrl} width="100%" height="800px"></iframe>
      </div>
    </>
  )
}
